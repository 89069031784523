/**
 * Copyright © 2020 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { flow } from 'lodash';
import { withDesign, addClasses } from '@bodiless/fclasses';
import { withMegaMenuDesign } from '@bodiless/organisms';

import {
  withBaseMenuStyles,
  withBaseSubMenuStyles,
} from './SimpleMenu.token';

/**
 * Touts Sub Menu Styles
 * ===========================================
 */
const asToutsSubmenu = withDesign({
  Item: addClasses('w-1/3'),
});

/**
 * Columns Sub Menu Styles
 * ===========================================
 */
const asColumnSubMenu = withDesign({
  Item: withDesign({
    Title: addClasses('pl-5'),
  }),
});

/**
 * Mega Menu Sub Menu Styles
 * ===========================================
 */
const withMegaMenuSubMenuStyles = withDesign({
  Item: withDesign({
    Touts: asToutsSubmenu,
    Columns: asColumnSubMenu,
  }),
});

/**
 * Mega Menu Styles
 * ===========================================
 */
const withMegaMenuStyles = flow(
  withMegaMenuDesign(withBaseSubMenuStyles),
  withMegaMenuSubMenuStyles,
  withBaseMenuStyles,
);

export default withMegaMenuStyles;
