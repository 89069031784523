/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * This file might move to live in a package some where
 */
import { addClasses } from '@bodiless/fclasses';

export const asAlignLeft = addClasses('text-left');
export const asAlignRight = addClasses('text-right');
export const asAlignCenter = addClasses('text-center');
export const asAlignJustify = addClasses('text-justify');
export const asUnderline = addClasses('underline');
export default {
  asAlignLeft,
  asAlignRight,
  asAlignCenter,
  asAlignJustify,
  asUnderline,
};
